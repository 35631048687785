import React from 'react';
import styles from './AboutUsPage.module.css';
import office from "../../assets/media/company/office.png";

const AboutUsPage: React.FC = () => {
    return (
        <div className={`${styles.aboutUsPageContainer} text-left text-secondary-light opacity-80 pt-header p-10 mb-20`}>
            <h1 className='text-5xl mt-10'>关于我们</h1>
            <div className={styles.grid}>
                <div className={styles.imgContainer}>
                    <p className='leading-7 my-10'>新愿景国际集团 New Vision International Group (NVIG) ，总部位于英国伦敦，是全球资产配置专业平台。我们秉承 “国际视野，本地专识 Global Vision, Local Expertise”的理念，为有全球化综合发展需求的企业及高净值人士和家族提供一站式定制服务。集团业务主要包括家族办公室、地产置业、科技投资、艺术文化、智库交流、私人俱乐部、剑桥基金会七大板块。</p>
                    <img className='mb-10' src={office} alt="an image of the street outside the office" />
                </div>
                {/* <div className='flex justify-between gap-12'> */}
                <Feature title="团队介绍" content="我们的创始团队均毕业于英国剑桥大学，在相关行业中拥有十余年高级管理经验和专业积淀。" />
                <Feature title="合规和合法性" content="新愿景集团极其注重其商业运营和发展的合规与合法性，各个业务板块均在英国相关权威机构监管下开展。" />
                <Feature title="承诺和愿景" content="我们期待用诚信、专业的职业准则，以您的满意与信任为宗旨，为您、您的家族、您的企业在全球化长足发展中保驾护航。" />
                {/* </div> */}
            </div>
        </div>
    );
};

export default AboutUsPage;

function Feature({ title, content }: { title: string, content: string; }) {
    return (
        <div className={styles.item}>
            <p className='text-main text-xl mb-6 font-bold'>{title}</p>
            <p className='leading-7'>{content}</p>
        </div>
    );
}