import React, { useContext, useState } from 'react';
import styles from "./Header.module.css";
import { NavLink, useLocation } from 'react-router-dom';
import logo from "../../assets/media/company/nvig-logo-horizontal.png";
import { GlobalContext } from '../../context/globalContext';

const Header: React.FC = () => {
    const location = useLocation();
    const { isSmallScreen } = useContext(GlobalContext);
    const [isOpen, setIsOpen] = useState(false);

    const isActive = (path: string): boolean => {
        return location.pathname === path;
    };

    function toggleMenu() {
        setIsOpen(!isOpen);
    }
    function closeMenu(e: React.MouseEvent) {
        let element = e.target as HTMLElement;
        while (element && element.nodeName !== 'A' && element !== e.currentTarget) {
            element = element.parentElement as HTMLElement;
        }
        if (element && element.nodeName === 'A') {
            setIsOpen(false);
        }
    }

    return (
        <header
            className={`${styles.headerContainer} flex w-screen justify-between items-center border-b-0.5 border-main border-opacity-40 fixed z-[100] bg-secondary-dark p-4 sm:p-0 flex-col sm:flex-row`}
            onClick={closeMenu}
        >
            <div className='flex justify-between w-full sm:w-auto items-center'>
                <NavLink to="/"><img className="h-8 sm:pl-6" src={logo} alt="company logo" /></NavLink>
                {isSmallScreen && <span className="material-symbols-outlined text-main hover:cursor-pointer" onClick={toggleMenu}>{isOpen ? "close" : "menu"}</span>}
            </div>
            {(!isSmallScreen || isOpen) &&
                <ul className='flex text-main pt-4 sm:pt-0'>
                    <li><NavLink to="/about-us" className={isActive('/about-us') ? styles.selected : ''}>关于我们</NavLink></li>
                    <li><NavLink to="/business-segments" className={isActive('/business-segments') ? styles.selected : ''}>业务板块</NavLink></li>
                    <li><NavLink to="/insights" className={isActive('/insights') ? styles.selected : ''}>行业资讯</NavLink></li>
                    <li><NavLink to="/contact-us" className={isActive('/contact-us') ? styles.selected : ''}>联系我们</NavLink></li>
                </ul>
            }
        </header>
    );
};

export default Header;
