import foBg from "../assets/media/background/preview/fo.jpg";
import reBg from "../assets/media/background/preview/re.jpg";
import techBg from "../assets/media/background/preview/tech.jpg";
import artBg from "../assets/media/background/preview/artall.jpg";
import thinkBg from "../assets/media/background/preview/think.jpg";
import clubBg from "../assets/media/background/preview/vclub.jpg";
import camBg from "../assets/media/background/preview/cambridge.jpg";

export const segments = [
    { subtitle: "新愿景", title: "家族办公室", link: "/business-segments/family-office", img: foBg, externalLink: "http://newvfo.com", top: "57%", left: "17%", topMobile: "33%", leftMobile: "40%" },
    { subtitle: "新愿景", title: "地产置业", link: "/business-segments/real-estate", img: reBg, externalLink: "https://nvre.co.uk", top: "83%", left: "43%", topMobile: "46%", leftMobile: "72%" },
    { subtitle: "新愿景", title: "科技投资", link: "/business-segments/technology", img: techBg, top: "62%", left: "69.5%", topMobile: "88%", leftMobile: "41%" },
    { subtitle: "新愿景", title: "艺术与文化", link: "/business-segments/artall", img: artBg, top: "43%", left: "56%", topMobile: "63%", leftMobile: "21%" },
    { subtitle: "智库与传媒", title: "N维智库", link: "/business-segments/think-tank", img: thinkBg, top: "53%", left: "37.5%", topMobile: "44%", leftMobile: "9%" },
    { subtitle: "V Club", title: "私人俱乐部", link: "/business-segments/private-club", img: clubBg, top: "37%", left: "73%", topMobile: "76%", leftMobile: "7%" },
    { subtitle: "新愿景", title: "剑桥基金会", link: "/business-segments/new-vision-cambridge-foundation", img: camBg, top: "82%", left: "60%", topMobile: "66%", leftMobile: "68%" },
];