import React from 'react';
import styles from './InsightsPage.module.css';
import bg from "../../assets/media/background/background3.jpg";
import { insights } from '../../data/insights';

const InsightsPage: React.FC = () => {
    return (
        <div className={`${styles.insightsPageContainer} text-white relative text-left`}>
            <img className="h-[40vh] w-screen object-cover" src={bg} alt="background" />
            <h1 className='text-secondary-light text-4xl absolute top-[20vh] ml-[50vw] translate-x-[-50%]'>行业资讯</h1>
            <div className={`p-10 border-t-0.5 border-main border-opacity-40 flex flex-col sm:flex-row gap-16 h-auto sm:h-[60vh] pb-10 sm:pb-2`}>
                <Insight info={insights[0]} special />
                <div className='flex gap-16 sm:gap-10 flex-col sm:flex-row'>
                    <Insight info={insights[1]} />
                    <Insight info={insights[2]} />
                </div>
            </div>
            <div className='bg-secondary-light  text-secondary-dark p-6 pb-52'>
                <p className='text-3xl font-bold mb-12 mt-20 ml-2'>更多资讯</p>
                <div className='flex flex-col gap-16 sm:gap-0 sm:grid sm:grid-cols-4 sm:gap-y-24'>
                    {insights.map((insight, index) => {
                        if (index > 2) {
                            return (
                                <Insight info={insight} />
                            );
                        }
                    })}
                </div>
            </div>
        </div>
    );
};

export default InsightsPage;

function Insight({ info, special = false }: { info: any, special?: boolean; }) {
    // function Insight({ special = false, img, title, subtitle, content }: { special?: boolean, img: string, title: string[], subtitle: string, content: string; }) {

    return (
        <a href={info.link} target="_blank" className={special ? "" : "w-full p-2 block"}>
            {special ?
                <div className='font-bold text-3xl opacity-90'>{info.title.map((t: any) => { return <p className='mb-1'>{t}</p>; })}</div>
                :
                <>
                    <img className='h-[25vh] sm:h-[15vw] w-full object-cover' src={info.img} alt="title" />
                    <p className='font-bold text-lg opacity-90 mt-3'>{info.title[0]}</p>
                </>
            }
            <p className={`${special ? "text-sm" : "text-xs"} opacity-50 `}>{info.subtitle}</p>
            <p className={`${special ? "text-sm" : "text-xs"} opacity-30 mt-3 mb-6`}>{info.date}</p>
            <p className={`${special ? "text-base" : "text-sm"} opacity-90 leading-relaxed`}>{info.content}</p>
        </a>
    );
}