import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import styles from './HomePage.module.css';
import segmentsImg from "../../assets/media/company/segments.png";
import segmentsMobileImg from "../../assets/media/company/segments-mobile.png";
import cambridgeLogo from "../../assets/media/company/cjbs.png";
import homeBackground from "../../assets/media/background/background3.jpg";
import { NavLink } from 'react-router-dom';
import { motion, useAnimation } from 'framer-motion';
import { segments } from '../../data/companyInfo';
import { GlobalContext } from '../../context/globalContext';


const HomePage: React.FC = () => {
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null);
    const [isThirdDiv, setIsThirdDiv] = useState(false); // State to track if the third div is reached
    const { isSmallScreen } = useContext(GlobalContext);

    const snapToSection = useCallback(() => {
        if (scrollContainerRef.current) {
            const viewportWidth = window.innerWidth;
            const currentScrollLeft = scrollContainerRef.current.scrollLeft;
            const currentSectionIndex = Math.round(currentScrollLeft / viewportWidth);

            // Check if the third div is reached
            setIsThirdDiv(currentSectionIndex === 2);

            const targetScrollLeft = currentSectionIndex * viewportWidth;
            scrollContainerRef.current.scrollTo({
                left: targetScrollLeft,
                behavior: 'smooth'
            });
        }
    }, []);

    const handleScroll = useCallback((e: WheelEvent) => {
        if (scrollContainerRef.current) {
            // Check if we're on the third div and the user is scrolling down
            if (isThirdDiv && e.deltaY > 0) {
                // Allow natural vertical scrolling
                return;
            }

            // Prevent default to stop natural vertical scrolling when not on the third div
            e.preventDefault();

            const delta = e.deltaY;
            const scrollSpeed = 1; // Adjust the scroll speed as needed
            scrollContainerRef.current.scrollLeft += delta * scrollSpeed;

            // Clear any existing debounce timeout
            if (debounceTimeoutRef.current) {
                clearTimeout(debounceTimeoutRef.current);
            }

            // Set a new debounce timeout to snap to section after scrolling has stopped
            debounceTimeoutRef.current = setTimeout(() => {
                snapToSection();
            }, 100); // Delay snapping until 100ms after the last scroll event
        }
    }, [isThirdDiv, snapToSection]);



    useEffect(() => {
        if (isSmallScreen) return;
        const container = scrollContainerRef.current;
        if (container) {
            container.addEventListener('wheel', handleScroll, { passive: false });
            window.addEventListener('resize', snapToSection);
        }

        return () => {
            if (container) {
                container.removeEventListener('wheel', handleScroll);
                window.removeEventListener('resize', snapToSection);
            }
            if (debounceTimeoutRef.current) {
                clearTimeout(debounceTimeoutRef.current);
            }

        };
    }, [handleScroll, isSmallScreen]);


    return (
        <div ref={scrollContainerRef} className={`${styles.homePageContainer}  text-main pt-md-header sm:pt-0 flex flex-col sm:flex-row gap-40 sm:gap-0`}>
            <img
                className='fixed w-screen h-screen object-cover'
                src={homeBackground} alt='image of london as a background'
            />
            <div className='flex flex-col items-start p-10 sm:p-14 sm:justify-center gap-16 text-left'>
                <div>
                    <h1 className='text-secondary-light font-poster text-6xl opacity-80 transform scale-y-125 mb-10 mt-10 text-left'>Global Vision<br />Local Expertise</h1>
                    <p className='text-secondary-light opacity-80 text-2xl mt-16 sm:mt-0'>国际视野，本地专识</p>
                </div>
                <p className='text-main opacity-80 sm:max-w-[40vw] text-left leading-8'>新愿景国际集团 New Vision International Group (NVIG) ，总部位于英国伦敦，是全球资产配置专业平台，为有全球化综合发展需求的企业及高净值人士和家族提供一站式定制服务。</p>
                <NavLink to="/about-us"><button className='btn-hollow'>了解更多</button></NavLink>
            </div>
            <div className='flex flex-col items-center justify-between p-8'>
                <div className='relative'>
                    <img className="mt-10 opacity-70 sm:opacity-100" src={isSmallScreen ? segmentsMobileImg : segmentsImg} alt="image of all seven business segments" />
                    {segments.map(segment => {
                        return <SegmentButton info={segment} />;
                    })}
                </div>
                <div className='mb-24 order-[-1] sm:order-1 '>
                    <h2 className='text-secondary-light text-4xl mb-4' >业务板块</h2>
                    <p className='font-poster text-secondary-light text-6xl'>Business Segments</p>
                </div>
                {!isSmallScreen && <Bottom />}
            </div>
            <div className='p-10 flex flex-col justify-between'>
                <div className='flex flex-col md:flex-row text-left justify-between gap-10 mt-20 mb-48 md:mb-0 md:mr-10 items-center '>
                    <div className='flex flex-col gap-10 items-start'>
                        <h2 className='font-poster text-secondary-light text-2xl skew-x-[-16deg] whitespace-nowrap ml-3'><span className='text-5xl sm:text-6xl'>"New vision</span><br /><span className='text-xl'>for Cambridge EMBA alumni</span><span className='text-5xl sm:text-6xl'>"</span></h2>
                        <p className='md:max-w-[30vw] leading-8'>英国剑桥大学专访了新愿景创始管理团队，分享集团的发展理念和显著成就。</p>
                        <a className="self-center sm:self-start" href='https://www.jbs.cam.ac.uk/insight/2021/new-vision-for-cambridge-emba-alumni/' target='_blank'><button className='btn-hollow'>阅读文章</button></a>
                    </div>
                    <div className='border border-main border-opacity-50 flex flex-col gap-0 md:gap-8 mt-16 md:mt-0'>
                        <p className='p-6 md:p-10 text-secondary-light text-2xl opacity-70'>The article at a glance</p>
                        <p className='p-6 md:p-10 md:max-w-[40vw] leading-loose text-secondary-light opacity-90 mb-6 md:mb-16'>Cambridge Executive MBA alumni and a Cambridge MBA alumna have come together to form New Vision International Group (NVIG) – a platform offering investment advice to Chinese high net worth individuals (HNWIs).</p>
                        <img className="bg-main p-3 w-40 self-end" src={cambridgeLogo} alt="cambridge-logo" />
                    </div>

                </div>
                <Bottom />
            </div>

        </div>
    );
};

export default HomePage;

function Bottom() {
    return (
        <div className='opacity-50'>
            <p>国际视野，本地专识</p>
            <p>Global Vision, Local Expertise</p>
        </div>
    );
}

function SegmentButton({ info }: { info: any; }) {
    const { isSmallScreen } = useContext(GlobalContext);
    return (
        <NavLink className={`absolute text-secondary-light font-bold hover:text-main`} style={{ "top": isSmallScreen ? info.topMobile : info.top, "left": isSmallScreen ? info.leftMobile : info.left }} to={info.link} >{info.title}</NavLink>
    );
}