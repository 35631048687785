import React from 'react';
import styles from "./BusinessSegmentsPage.module.css";
import { NavLink } from 'react-router-dom';
import ciol from "../../assets/media/company/ciol.png";
import ilpa from "../../assets/media/company/ilpa.png";
import oisc from "../../assets/media/company/oisc.png";
import alra from "../../assets/media/company/alra.png";
import { segments } from '../../data/companyInfo';


const BusinessSegmentsPage: React.FC = () => {
    return (
        <div className={`${styles.businessSegmentsPage} text-secondary-light pt-header`}>
            <div className=''>
                {segments.map(({ link, subtitle, title, img }, index) => {
                    if (index === 0) {
                        return (
                            <>
                                <Segment img={img} subtitle={subtitle} title={title} link={link} />
                                <div className={`${styles.heading} text-main text-6xl text-right flex flex-col items-center justify-end py-10 px-2 leading-normal font-bold`}><h1>七大<br />业务板块</h1></div>
                            </>
                        );
                    }
                    return <Segment img={img} subtitle={subtitle} title={title} link={link} />;
                })}
            </div>
        </div>
    );
};

export default BusinessSegmentsPage;

// ---------- helper components ---------- //
function Segment({ img, subtitle, title, link }: { img: any, subtitle: string, title: string, link: string; }) {
    return (
        <NavLink to={link} className='relative border-0.5 border-main border-opacity-30 flex flex-col justify-end text-left hover:cursor-pointer'>
            <img className="absolute opacity-[7%] h-full w-full object-cover" src={img} alt="background" />
            <div className='relative z-30 p-10 mt-auto flex flex-col gap-4 items-start'>
                <p className='opacity-40'>{subtitle}</p>
                <p className='font-extrabold text-3xl'>{title}</p>
                <button className='btn-hollow-sm'>了解更多</button>
            </div>
        </NavLink>
    );
}

// ---------- helper components ---------- //
function Nav({ index }: { index: number; }) {
    function isSelected(i: number) {
        return i === index ? styles.selected : styles.unselected;
    }

    return (
        <ul className={`flex justify-evenly text-main ${styles.nav} mt-2 sm:mt-0`}>
            {segments.map((segment, index) => {
                return (
                    <li className={`${isSelected(index)} ${styles.navLi} p-3 border-l-0.5 border-b-0.5 border-main border-opacity-40 flex-grow text-center  `}><NavLink to={segment.link}>{segment.title}</NavLink></li>
                );
            })}
        </ul>
    );
}

export function SegmentPageFO() {
    return (
        <SegmentPageTemplate index={0} content={[
            "新愿景家族办公室（New Vision Family Office，简称NVFO），秉承“洞悉时局变迁，专业规划配置，稳定增值传延”的理念，为诸多中国的超高净值家族提供一站式全球资产配置、身份和教育规划、财富管理传承和私人银行等服务。",
            "新愿景家族办公室是英国内政部移民事务署（OISC）特许法律机构，并受其监管，为客户提供最专业的法律咨询和合规服务，迄今为止已为数百组客户与家庭成功完成移民身份规划，享受全球公民的便利与福利。",
            "我们的创始团队均毕业于世界知名学府英国剑桥大学，用多年研学所成与行业专业积淀，以您和家族的需求为基石，打造一个专于您且适配于您的，高度前瞻性、私隐性、专业性和可持续发展性的家族办公室综合服务。",
        ]}
            link image={
                <div className='flex flex-col gap-3 w-[40vw] sm:w-[15vw] opacity-70 mx-auto'>
                    <img src={oisc} alt="oisc's logo" />
                    <img src={ilpa} alt="ilpa's logo" />
                    <img src={ciol} alt="ciol's logo" />
                </div>
            } />

    );
}

export function SegmentPageRE() {
    return (
        <SegmentPageTemplate index={1} content={[
            "新愿景地产New Vision Real Estate（NVRE）是一家总部位于英国伦敦的高端地产咨询公司，受英国权威房产监管机构ARLA Propertymark认证，为高净值客户及家族企业提供专业高效的地产交易及租赁管理服务。我们的创始团队成员均毕业于剑桥大学，在英国的地产置业领域拥有多年的管理经验。专业性是广大客户选择我们的首要因素。",
            "无论您是以个人名义买自住或投资房产，还是以公司名义进行房产配置，我们的专家团队提供定制化专业服务以及优质的客户体验。我们对在英国和离岸设立SPV和信托架构等资产配置工具有丰富的行业经验，已经成功帮助众多高净值客户及家庭的地产置业进行投资组合优化。",
            "对于房产投资组合，我们的资深房产专家悉心了解客户的需求，提供综合全面的房源数据分析，为最终决策提供建设性指导意见。具体的房产分析是在基于风险的评估策略（Risk-Based Assessment）下进行的。在考虑宏观社会经济的动态变化的同时，运用最前沿的微观大数据库，通过定性分析（Qualitative Analysis）与定量分析（Quantitative Analysis）相结合的方法，对房产建立全面的评分体系。这个评分体系包括房源的固有价值、升值潜力、租金回报等20多个要素，并且根据客户实际需求进行加权。每一位客户都会得到一套量身定制的房产投资一体化解决方案。",
            "我们的专业团队还提供置业流程中必不可少的增值服务，包括合作银行贷款、律师对接、税务规划、以及子女择校入学等安居服务。 ",
        ]}
            link large image={<div>
                <img src={alra} alt="alra's logo" />
            </div>} />

    );
}

export function SegmentPageTech() {
    return <SegmentPageTemplate index={2} content={[
        "新愿景科技New Vision Technology（NVT）是一家总部位于英国伦敦的科技投资咨询平台及科技企业加速器。我们为英国顶尖高校衍生的优质科技企业有效的对接中国的资本及市场，并且为中国的高净值投资人，家族企业及跨境基金提供科技项目投资的商业咨询及本地化管理服务。我们以科技为引领，产业为驱动，国际人才为支撑，构建一个中英两国相互联动的创业创新生态。",
        "NVT的创始团队在英国科技投资领域拥有超过15年的科技投资及管理咨询行业经验，与英国科技创新“金三角”区域的剑桥大学、牛津大学、帝国理工学院保持紧密合作关系。我们有效连接英国最前沿的科研创新项目与中国完备的产业链生态、以及全球最大单一市场，积极推动高校科技成果转化和衍生企业的发展，促进中英科技的信息交流与合作。"
    ]} />;
}

export function SegmentPageArt() {
    return <SegmentPageTemplate index={3} content={[
        "新愿景Artall（New Vision Artall）是一家总部位于英国伦敦的艺术文化传播公司，提供高端艺术品收藏与投资权威咨询，促进东西方艺术文化交流，伴您轻松走进高端艺术圈。",
        "我们的核心团队由业内不同领域的资深专家组成，其中多位专家为百年历史的英国东方陶瓷协会The Oriental Ceramic Society （OCS）资深会员。我们拥有超过20年的中西方艺术品收藏与投资经验，以及顶级的艺术行业资源，包括世界一流的拍卖行、画廊、艺术博览会、艺术品经纪人和古董商。 我们为艺术机构、艺术家、收藏家及艺术投资者提供全方位的定制艺术服务。",
        "我们作为东西方艺术文化交流的桥梁，直接对接国际知名的博物馆、艺术院校等机构，举办艺术展览、文化论坛等一系列线上线下活动，涉及领域包括中国书画、古陶瓷、茶文化和宗教艺术等。 "
    ]} />;
}

export function SegmentPageThinkTank() {
    return <SegmentPageTemplate index={4} content={[
        "N维智库N-Vision Think Tank（NVTT），成立于英国伦敦，秉承“汇聚全球精英，共享持续发展”的理念，保持“客观、深度、致用”的态度，专注于提高业界对全球资产配置和财富管理最佳实践的知识。NVTT平台是由中外知名高等学府学者与行业资深专家构成。",
        "我们致力于在全球化发展背景下，深耕国际经济、金融税务、资产管理、教育、艺术与文化等领域，为高净值人士与家庭提供最前沿信息与整合资讯。"
    ]} />;
}

export function SegmentPageClub() {
    return <SegmentPageTemplate index={5} content={[
        "V Club是位于英国伦敦的高端生活方式私人俱乐部，为高净值人士的社交与兴趣发展提供聚合与交流平台。 ",
        "兴起于英国的私人俱乐部制度，让伦敦成为当之无愧的全球私人俱乐部中心。我们欢迎对艺术收藏、帆船游艇、飞行、高尔夫、红酒等感兴趣的高净值人士加入，并参加我们定期组织的私人俱乐部活动，包括艺术展、拍卖会、马球会、酒会等等，在这里享受英国生活乐趣、发展兴趣爱好、扩展人脉网络。 "
    ]} />;
}

export function SegmentPageCambridge() {
    return <SegmentPageTemplate index={6} content={[
        "新愿景剑桥基金会（New Vision Cambridge Foundation），是集团企业社会责任感公益项目。 ",
        "“投我以桃，报之以李”，剑桥大学悠久的历史、浓厚的学术环境、广阔的社会联系，孕育了一代又一代具有企业家精神的优秀校友，孵化了一批批具有社会责任感的全球化企业。新愿景集团创始团队均毕业于剑桥大学，愿用企业的收获，支持剑桥大学莘莘学子，以及和新愿景集团价值观趋同的创新创业项目。 "
    ]} />;
}

// ---------- helper components ---------- //
function SegmentPageTemplate({ index, content, link = false, large = false, image = null }: { index: number, content: string[], link?: boolean, large?: boolean, image?: any; }) {
    return (
        <div className='h-auto sm:min-h-screen text-secondary-light relative pt-header text-left'>
            <Nav index={index} />
            <img className='absolute opacity-5 h-screen w-screen object-cover' src={segments[index].img} />
            <div className='relative z-50 p-10 flex flex-col sm:flex-row gap-10 items-center h-full pb-28'>
                <div>
                    <div className='mb-12 flex gap-10'>
                        <h1 className='font-bold text-4xl'>新愿景{segments[index].title}</h1>
                        {/* {link && <a href={segments[index].externalLink} target='_blank'><button className='btn-hollow text-main'>了解更多</button></a>} */}
                    </div>
                    <div className={`leading-loose ${large ? "sm:max-w-[70vw]" : "sm:max-w-[60vw]"}`}>
                        {content.map(content => {
                            return <p className={`${large ? "mb-5 text-base" : "mb-10"}`}>{content}</p>;
                        })}
                    </div>
                </div>
                {image && image}
            </div>
        </div>
    );
};