// export const insights = [
//     { title: "", subtitle: "", content: "", img: "" },
// ];

export const insights = [
    { title: ['2024年', '家族办公室趋势：', '追求韧性'], subtitle: '资管专家团队', content: '每年的这个时候，科技公司都会向我们展示“倒带的一年”，而顾问和金融机构也会发表他们的远见卓识。回顾过去几年的各种预测，很明显“波动性”这个词在聚光灯下出现的时间过长。', link: 'https://mp.weixin.qq.com/s/XvFSkDMbhUoV8HyTmRWvvw', index: 277, date: '2024-01-09', img: require('../assets/media/insights/277.jpeg') },
    { title: ['艺术文化 | 2023年中国瓷器拍卖之最'], subtitle: '艺术文化', content: '迈入2024年之际，新愿景Artall祝各位读者朋友新年快乐！本期推文，我们回顾一下2023年中国古董艺术品市场的发展和那些叱咤艺术品拍卖市场的珍品。', link: 'https://mp.weixin.qq.com/s/dLveWiERo9zREq3laVgN8w', index: 278, date: '2024-01-10', img: require('../assets/media/insights/278.jpeg') },
    { title: ['2024英国经济预测：有望避免硬着陆，预计增长率为0.3%'], subtitle: '资管专家团队', content: '彭博社对52位经济学家的调查，预计2024年英国经济将避免衰退并在下半年加强，得益于通胀下降和生活成本危机的缓解。尽管面临全球地缘政治风险和内部经济挑战，但政府预算措施和劳动力市场的强劲可能支持经济复苏，预计增长率为0.3%。', link: 'https://mp.weixin.qq.com/s/yu_e-XIRf6eNY3LwAWhhMw', index: 276, date: '2024-01-05', img: require('../assets/media/insights/276.jpeg') },
    { title: ['投行预测：英国经济今年表现出韧性，2024年将增长？'], subtitle: '投行预测', content: '四大投行之一的高盛研究显示：今年英国经济表现出令人惊讶的韧性，其前景在一些重要方面与其他发达市场趋同。高盛研究部首席欧洲经济学家Jari Stehn在该团队题为《2024年英国展望：毕竟没那么不同》的报告中写道，预计2024年英国GDP增长将小幅改善。', link: 'https://mp.weixin.qq.com/s/M-TudPNo_eqjrAZOAfthvA', index: 275, date: '2023-12-13', img: require('../assets/media/insights/275.jpeg') },
    { title: ['英国削减移民出狠招，五点计划明年春生效！'], subtitle: '资管专家团队', content: '英国官方估计 2022 年净移民人数将达到创纪录的 745,000 人，内政大臣本周一向下议院发表了一份非常重要的声明，制定了削减家庭、学习和工作相关移民的计划。首相里希·苏纳克最近表示，移民水平过高，需要降至可持续水平。', link: 'https://mp.weixin.qq.com/s/o6mZiACzfqcZhJp8u_H9FA', index: 274, date: '2023-12-05', img: require('../assets/media/insights/274.jpeg') },
    { title: ['一分钟看懂英国秋季财算！哪些变动影响你的钱袋子？'], subtitle: '资管专家团队', content: '杰里米·亨特 (Jeremy Hunt)宣布英国经济“重回正轨”，并声称他的英国经济计划正在“奏效”。我们不妨一起看看秋季声明中的详细内容。', link: 'https://mp.weixin.qq.com/s/5uLo1-gKyYhFERbiMalUHg', index: 273, date: '2023-11-22', img: require('../assets/media/insights/273.jpeg') },
    { title: ['英国《金融时报》：在新加坡设立家族办公室，目前需要长达18个月时间'], subtitle: '资管专家团队', content: '贸工及文化、社区和青年部国务部长表示，新加坡金融管理局（MAS）在审查后可能会在必要时收紧内部激励管理。据央行称，新加坡注册的家族办公室数量从2020年的400个和2018年的 50个激增至2022年底的1100个。', link: 'https://mp.weixin.qq.com/s/Rxp-Yaifux6fzHqLNrCrbw', index: 272, date: '2023-11-22', img: require('../assets/media/insights/272.jpeg') },
    { title: ['英国央行：通胀率下降，明年大概率降息'], subtitle: '资管专家团队', content: '英国央行首席经济学家皮尔表示，由于能源价格下降，英国通胀率将很快与世界其他地区的低利率水平保持一致。10月份通胀率将“进一步急剧下降”至5%以下，这将有助于部分弥合英国和美国、欧元区价格上涨之间的差距。', link: 'https://mp.weixin.qq.com/s/UBNAD9bNSgpvUtsBPo2mLg', index: 271, date: '2023-11-14', img: require('../assets/media/insights/271.jpeg') },
    { title: ['英国前首相卡梅伦复出！现出任外交大臣'], subtitle: '资管专家团队', content: '据英国《卫报》13日报道，英国前首相戴维·卡梅伦被任命为英国外交大臣。此前担任外交大臣的詹姆斯·克莱弗利被任命为内政大臣，以接替稍早前被解职的苏埃拉·布雷弗曼。', link: 'https://mp.weixin.qq.com/s/sB1shMmjytOpxzq0clg0xQ', index: 270, date: '2023-11-13', img: require('../assets/media/insights/270.jpeg') },
    { title: ['最新 | 即日起双认证正式取消，移民周期将迎来史诗级简化'], subtitle: '资管专家团队', content: '中国正式加入海牙公约，11月7日起涉外文书不再需要双认证，简化公文书跨国流转程序，预示着中国的签证和移民流程迎来史诗级简化。', link: 'https://mp.weixin.qq.com/s/Q9fKGfkS4LSyOBMC1UJpnw', index: 269, date: '07/11/2023', img: require('../assets/media/insights/269.jpeg') },
    { title: ['好消息！英国或将宣布取消新建房屋Leasehold租赁产权'], subtitle: '资管专家团队', content: '英国住房大臣雷切尔·麦克林(Rachel Maclean)确认，一项逐步取消英格兰和威尔士部分租赁权的法案将纳入国王的演讲中。11 月 7 日的讲话将制定来年的政府计划。大臣们长期以来一直承诺改变有争议的租赁制度Leasehold，该制度向房主征收昂贵的费用。预计该法案将禁止新房的租赁权，但不会禁止新公寓的租赁权。', link: 'https://mp.weixin.qq.com/s/Lmd7XAbm9K55adt0xOU2NQ', index: 268, date: '2023-11-01', img: require('../assets/media/insights/268.jpeg') },
    { title: ['科技投资| 英国1 亿英镑基金加速人工智能在生命科学和医疗保健领域的应用'], subtitle: '科技投资', content: '英国首相宣布的一项新使命将加速在生命科学中的人工智能应用，以应对我们这一代人面临的最大健康挑战。在周四的演讲中，首相宣布 1 亿英镑的新政府投资将针对人工智能快速部署最有潜力为以前无法治愈的疾病治疗带来变革性突破的领域。', link: 'https://mp.weixin.qq.com/s/z4oYZbLHSulnnZsjSuaD3w', index: 267, date: '2023-10-30', img: require('../assets/media/insights/267.jpeg') },
    { title: ['家族办公室成功运营的要素有哪些？'], subtitle: '资管专家团队', content: '家族办公室受到外部条件和内部要求的影响，受到很高的期望。未来成功的家族办公室会是什么样子？ 在快速发展的21世纪全球化世界中，家族办公室在管理庞大而复杂的家族资产方面的重要性日益凸显。他们面临着保护、增加财富并将其转移给子孙后代的挑战。同时，它们会受到外部条件和内部要求的影响而不断变化。', link: 'https://mp.weixin.qq.com/s/Iis4uvBfkEY6e2MLdBvAyg', index: 266, date: '2023-10-19', img: require('../assets/media/insights/266.jpeg') },
    { title: ['葡萄牙黄金签证颁布新政，诸多细节详解'], subtitle: '资管专家团队', content: '就在国庆前夕的9月30日，葡萄牙总统马塞洛·雷贝洛·德索萨颁布了备受争议的《更多住房》法案，法案中包括葡萄牙黄金签证的新政策。新政在10月1日生效。此次葡萄牙黄金签证新政，只将基金作为讨论范围，所以此类投资被市场观察人员预计将成为首选的合格投资渠道。', link: 'https://mp.weixin.qq.com/s/rhYtjGRawBEG9WJGbFbsxQ', index: 265, date: '2023-10-06', img: require('../assets/media/insights/265.jpeg') },
    { title: ['专家分析：英国贷款利率15个月以来首次“停滞”，这是什么征兆？'], subtitle: '资管专家团队', content: '去年年中，英国央行基准利率开始大幅上涨。自此，抵押贷款利率已从2015年初以来借款人享有的历史低点大幅上升，要知道当时平均两年期固定利率降至2%以下，平均利率下降至2%以下，五年期固定利率跌幅低于3%。', link: 'https://mp.weixin.qq.com/s/IhtjI3zoiRZ3VSxsxGP3Rg', index: 264, date: '2023-09-27', img: require('../assets/media/insights/264.jpeg') },
    { title: ['家族办公室 | 家族企业的增长速度几乎是发达经济体的两倍'], subtitle: '家族办公室', content: '大型家族办公室通常对投资方式和时间几乎没有强制要求，通常会尽早投资于最有前途的长期投资趋势，并具有潜在的强劲风险调整回报。通过投资公共和私人市场，家族办公室可以拥有独特的视角。来自私人投资的数据可以帮助了解其公开市场投资估值。作为成功的企业家和企业经营者，他们还可以为他们投资或直接收购的公司带来差异化的战略专业知识。', link: 'https://mp.weixin.qq.com/s/BdCPCyILExSWS5YrgnhESg', index: 263, date: '2023-09-26', img: require('../assets/media/insights/263.jpeg') },
    { title: ['改变游戏规则？英国设立新机制基金，以促进科学和技术研究'], subtitle: '家族办公室', content: '根据近日公布的新计划：政府对英国新研究企业的投资高达5000万英镑，将鼓励私人和慈善机构对英国科学、研究和创新提供更多支持。这将有利于更好的发展经济，对于寻找解决致命疾病的新方法以及利用支撑未来经济的新技术至关重要。Research Ventures Catalyst的推出将推动更多私人和慈善资金投入英国研发，巩固伦敦金融城作为国际金融中心的优势地位，造福我们的科学家和创新者', link: 'https://mp.weixin.qq.com/s/mTtLlH3wt_5rHnfQ-Y-THg', index: 262, date: '2023-09-18', img: require('../assets/media/insights/262.jpeg') },
    { title: ['英国经济在新冠疫情期间强劲复苏'], subtitle: '家族办公室', content: '根据经过大幅修改的官方数据，2021 年底疫情期间英国经济的复苏比之前的预期更为强劲。数据显示，2021 年最后三个月的经济比大流行前的水平增长了 0.6 %。此前的数据显示，英国经济萎缩了1.2 %。政府表示，这表明“那些决心贬低英国经济的人已被证明是错误的”。英国国家统计局表示，变化主要是因为其年度调查获得了“更丰富的数据”。修订后的数据还显示，2020 年大流行封锁对经济造成的崩溃程度比想象的要小，产出下降了 10.4 %，而不是 11 %。2021 年的复苏也更快，增长率为 8.7 %，而不是 7.6 %。', link: 'https://mp.weixin.qq.com/s/DetaLdBEbNrjxCj1k8BHow', index: 261, date: '2023-09-04', img: require('../assets/media/insights/261.jpeg') },
    { title: ['全球家族办公室都投资在哪里？一项最新调查出炉'], subtitle: '家族办公室', content: '全球家族办公室最喜欢投资在哪里？最近，一项调查报告《家族办公室投资洞察报告》揭秘了富豪都如何进行资产配置。在此报告中，全球超过166个家族办公室（57%在美洲；21%在欧洲、中东和非洲，22%在亚太地区）接受了调查。四分之三的人的净资产超过10亿美元，13%的人的净资产超过100亿美元。', link: 'https://mp.weixin.qq.com/s/0lof5rnIwf_udR5sJwXZ8w', index: 260, date: '2023-08-22', img: require('../assets/media/insights/260.jpeg') },
];

